<template>
<div>
    <unBookingSuccess />
</div>
</template>

<script>
import unBookingSuccess from '../../components/dashboard/booking/UnbookSuccess'
export default {
    components: {
        unBookingSuccess
    }
}
</script>

<style>
